
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */

// Imports
import AbstractModule from './AbstractModule';
import { $window } from '../utils/environment';

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
      // Required vars
      let _ss_curr = 0;
      let _ss_total = this.$el.find('.slides .item').length;
      let _ss_timer;
      let _ss_first = true;
      let _ss_this = this.$el;

      // Transition function
      function _ss_trans() {
        // Reset selected state
        _ss_this.find('.slides .item').removeClass('selected previous');
        _ss_this.find('.text .item').removeClass('selected previous');
        _ss_this.find('.dots a').removeClass('selected');

        // Update selected state
        _ss_this.find('.slides .item').eq(_ss_curr).addClass('selected').prevAll().addClass('previous');
        _ss_this.find('.text .item').eq(_ss_curr).addClass('selected').prevAll().addClass('previous');
        _ss_this.find('.dots a').eq(_ss_curr).addClass('selected');
      }

      // Ticker function
      function _ss_ticker() {
      	// Clear old timer
      	clearTimeout(_ss_timer);

      	// Reset timer
      	_ss_timer = setTimeout(_ss_ticker, 4000);

        // If first run
        if ( !_ss_first ) {
          // Set the value to the next slide
          _ss_curr++;

          // If greater than total, reset to begining
          if (_ss_curr > (_ss_total-1)) { _ss_curr = 0; }

          // Transition to selected slide
          _ss_trans();
        } else {
          _ss_first = false;
        }
      }

      // Timer stop function
      function _ss_timer_stop() {
      	// Clear old timer
      	clearTimeout(_ss_timer);
      }

      // Setup slideshow if it exists
      if ( _ss_total > 0) {
        // Set first slide visible
        _ss_this.find('.slides .item').eq(_ss_curr).addClass('selected');
        _ss_this.find('.text .item').eq(_ss_curr).addClass('selected');

        // We have more than one, so lets tie in autoplay, slide selectors
        if ( _ss_total > 1 ) {
          // Show controls
          _ss_this.find('.dots').removeClass('hide');

          // Set controls selector to selected
          _ss_this.find('.dots a').eq(_ss_curr).addClass('selected');

          // Give selectors click events
          _ss_this.find('.dots a').click( function(e) {
          	// Update selected slide number
            _ss_curr = $(this).parent().children().index(this);

          	// Turn off autoplay
          	_ss_timer_stop();

          	// Transition to selected slide
          	_ss_trans();
          });

          // Start autoplay
          _ss_ticker();
        }
      }
    }

    destroy() {
        super.destroy();
    }
}
