
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */

// Imports
import AbstractModule from './AbstractModule';
import { loadInitialCart } from '../utils/cart';

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
      // Load initial cart state
      loadInitialCart();

      // Close cart drawer
      $('.cart-close').click(function() {
        if ( $( 'body' ).hasClass( 'has-cart-open' ) ) {
          $( 'body' ).removeClass( 'has-cart-open' );
        }
      });
    }

    destroy() {
        super.destroy();
    }
}
