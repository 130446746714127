
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */
/* globals grecaptcha */

// Imports
import AbstractModule from './AbstractModule';

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
      // Set component target
      let thisComponent = $( this.el );

      // Target components
      let buttonSubmit = thisComponent.find( 'button[type="submit"]' );
      let buttonReset = thisComponent.find( 'button[type="reset"]' );
      let buttonClose = thisComponent.find( '.close' );

      // Serialize form data, even when it includes files
      function serializeFiles( form ) {
        let formData = new FormData();
        let formParams = form.serializeArray();

        $.each(form.find('input[type="file"]'), function(i, tag) {
          $.each($(tag)[0].files, function(i, file) {
            formData.append(tag.name, file);
          });
        });

        $.each(formParams, function(i, val) {
            formData.append(val.name, val.value);
          //formData[val.name] = val.value; 
        });
        // Return data to source of call
        return formData;
      }

      $('.dca-contact-block').on('submit', '#contact-form', function(e) {
        e.preventDefault();
        $('#form-errors').hide();
        $('#form-sucess').hide();
        $('#contact-submit').attr('disabled', true);
        $.post({
          url: '/',
          dataType: 'json',
          data: $(this).serialize(),
          success: function(response) {
              if (response.success) {
                $('#form-sucess').slideDown();
              } else {
                  if (response.error !== undefined) {
                    $('#contact-submit').attr('disabled', false);
                    grecaptcha.reset();
                    var errorhtml = '<ul>';
                    $.each(response.error, function(k, v) {
                      errorhtml = errorhtml + '<li>' + v[0] + '</li>';
                    });
                    errorhtml += '</ul>';
                    console.log(errorhtml);
                    $('#form-errors').html(errorhtml);
                    $('#form-errors').slideDown(500);
                  }
              }
          }
        });
      });

      // Close form drawer
      buttonClose.click(function() {
        $( 'body' ).removeClass( 'has-request-quote has-custom-order' );
      });

      // Reset form drawer
      buttonReset.click(function() {
        // Clear all inputs
        thisComponent.find( 'input, textarea' ).val( '' );
      });
    }

    destroy() {
        super.destroy();
    }
}
