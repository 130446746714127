
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */

//import TransitionManager from './transitions/TransitionManager';
import Scrollbar from 'smooth-scrollbar';
import { ParallaxScroll } from './parallaxScroll';
import { $window } from './environment';
import { detectIE } from './browser';

export default function( firstBlood ) {
    //svg4everybody();

  if ( firstBlood ) {
    //const transitionManager = new TransitionManager();

    // Setup smooth scrolling
    /*if ( $( 'body' ).hasClass( 'has-smooth-scroll' ) ) {
      // Init
      const smoothScroll = Scrollbar.init(document.querySelector('.js-scroll'), {
        syncCallbacks: true
      });

      // Our element
      const smoothFixed = $( '.js-fixed-sidebar-header' ).css('top', 0);

      // Rework for fixed sidebar/header items
      smoothScroll.addListener(({ offset }) => {
        if ( window.innerWidth < 960 ) {
          smoothFixed.css( 'top', offset.y + 'px' );
        } else {
          smoothFixed.css( 'top', 0 );
        }
      });
    }*/

    // Handle requet a quote modals
     /*
    $( '.js-request-quote' ).click( function( e ) {
      // Prevent loading of actual linkage
      e.preventDefault();

      // Ensure other overlays are closed
      $( 'body' ).removeClass( 'has-cart-open has-custom-order' );

      // Toggle opening based on current state
      if ( $( 'body' ).hasClass( 'has-request-quote' ) ) {
        $( 'body' ).removeClass( 'has-request-quote' );
      } else {
        $( 'body' ).addClass( 'has-request-quote' );
      }
    });*/

    // Handle cart overlay
    $( '.js-cart-close' ).click( function( e ) {

      // Ensure other overlays are closed
      $( 'body' ).removeClass( 'has-cart-open' );

    });

    // Setup parallax scrolling
    new ParallaxScroll( '.js-animate' );

    // Launch Lightcase
    $( 'a[data-rel^=lightcase]' ).lightcase();

    if ( detectIE() ) {
      $( 'html' ).addClass( 'isIE' );
    }
  }
}
