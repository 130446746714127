
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */

// Imports
import AbstractModule from './AbstractModule';
import { $window } from '../utils/environment';

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
      // Required vars
      let _gal_this = this.$el;
      let _gal_id = 'lightcase:' + this.uid;

      // Replace id's and make lightcase galleries unique
      _gal_this.find( '.thumbnail-wrapper .thumbnail' ).attr( 'data-rel', _gal_id );

      // Launch Lightcase
      $( 'a[data-rel^=lightcase]' ).lightcase();
    }

    destroy() {
        super.destroy();
    }
}
