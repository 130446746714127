
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */

// Imports
import AbstractModule from './AbstractModule';

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
      // If checkbox changes
      $( '#same-address' ).change(function() {
        var $sameAddress = $(this);
        if ($sameAddress.prop('checked') === true) {
          $('#billing-address').addClass('u-hide');
        } else {
          $('#billing-address').removeClass('u-hide');
        }
      });

      $('#billing-address').addClass('u-hide');
    }

    destroy() {
        super.destroy();
    }
}
