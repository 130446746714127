'use strict';
const APP_NAME     = 'boilerplate';
const DATA_API_KEY = '.data-api';

const $document    = $(document);
const $window      = $(window);
const $html        = $(document.documentElement).removeClass('has-no-js').addClass('has-js');
const $body        = $(document.body);

const isDebug      = !!$html.data('debug');

const requestAnimFrame = (function(functionToRun){
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        function(f){window.setTimeout(functionToRun, 1000/60);};
 })();

export { APP_NAME, DATA_API_KEY, $document, $window, $html, $body, isDebug, requestAnimFrame };
