
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */
/* globals currURI */

// Imports
import AbstractModule from './AbstractModule';
import { $window } from '../utils/environment';
import { addToCart } from '../utils/cart';
import { isArray } from '../utils/is';
import { addToArray, arrayContains } from '../utils/array';
//const formatCurrency = require( 'format-currency' );

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
      // Setup variables for reuse
      let quantityPricingByBulk = $( this.el ).attr( 'data-bulk-pricing' );
      let quantityPricingByBulkAll = $( this.el ).data( 'bulk-all' );
      var ajaxProductID = $( this.el ).attr( 'data-product-id' );
      let ajaxCSRFtoken = $( this.el ).attr( 'data-csrf-token' );
      let productURL = $( this.el ).attr( 'data-product-uri' );
      let initialSKU = $( this.el ).attr( 'data-initial-sku' );
      let variantData;
      let currentVariant;
      let typeSelector;
      let colorSelector;
      let currQty;
      let countTypes = $( '#variantSwitchType' ).find( 'option' ).length;
      let countColors = $( '#variantSwitchColor' ).find( 'option' ).length;
      var ajaxProductCanHaveArtFilesAttached;
      var el = this.el;
      //////
      //////
      ////// Main functions and processes
      //////
      //////

      // Function for returning pricing by bulk when
      // supplied price is found within a range
      function getPricingByBulk( _qty_ ) {
        // Result holder
        let bulkPrice;

        // Loop through bulk pricing array and find
        // the correct price associated, based on qty
        $.each( JSON.parse( quantityPricingByBulk ), ( index, item ) => {
          // Variables
          let minQty = item.minQuantity;
          let maxQty = item.maxQuantity || 2000000;

          // Search ranges and find the correct result, then return
          if ( ( _qty_ >= minQty ) && ( _qty_ <= maxQty ) ) {
            // Set result
            bulkPrice = item.price;
          }
        });

        // Return result
        return parseFloat( bulkPrice );
      }

      // Function for returning sliding rate pricing
      function setSlidingRate( basePrice, start, end, rate ) {
        // Feed data
        let init = parseFloat( basePrice );
        start = parseInt( start );
        end = parseInt( end );
        rate = parseFloat( rate );

        // Loop through
        while ( start < end ) {
          init = (init/rate);
          start++;
        }

        // Return result
        return init;
      }

      // Set availble color options based on selected type
      function setColorOptions( _title_ ) {
        // Build an array of colorId's available with current type
        let colorsAvail = [];

        // Loop and build into colorsAvail
        $.each(variantData, function( i, v ) {
          // Search for colors with current type
          if ( v.title === _title_ ) {
            // Push color id into array
            addToArray( colorsAvail, parseInt( v.color.colorId ) );
          }
        });

        // Now loop through color dropdown and enable those
        // that exist within the currently selected type
        $( '#variantSwitchColor > option' ).each(function() {
          // By default let's disable
          $( this ).prop( 'disabled', false );

          // By default let's add "NA" text
          $( this ).text(function () {
            return $(this).text().replace( ' (Not available in this size)', '' );
          });

          // Check if color is available and if so, re-work options
          if ( !arrayContains( colorsAvail, parseInt( $( this ).val() ) ) ) {
            // Enable
            $( this ).prop( 'disabled', true );

            // Remove "NA" text
            $( this ).append( ' (Not available in this size)' );
          }
        });
      }

      // Update the current variant
      function updateCurrentVariant() {
        // Hold current cart
        let currentCart = JSON.parse( $( '#variantSwitch' ).attr( 'data-cart-items' ) );

        // Set selectors
        let variantOptions = $( '#variantOptionsForm' ).find( 'select[name^="options"]' );
        let variantEngraving = $( '#variantOptionsForm' ).find( 'select[data-engrave="yes"]' );

        // Set variable holders
        let variantPriceDefault = parseFloat( currentVariant.price );
        // console.log('init variantPriceDefault: '+variantPriceDefault);
        let variantPriceModifier = parseFloat( 0 );
        let variantPriceCleaned;
        let variantMinQty = parseInt( currentVariant.minQty );
        let variantMaxQty = parseInt( currentVariant.maxQty );
        let variantSlidingRate = currentVariant.variantEachScale;
        let variantCurrQty = parseInt( $( '#variantOptionsQty' ).val() );
        let variantQtyOffset = 0;
        let variantMaxed = false;
        

        // In cases where bulk or sliding scale apply, check
        // for offset of related items already in the cart
        if ( quantityPricingByBulk || variantSlidingRate.length > 0 ) {
          // Determine whether to apply at product level or variant level
          if ( quantityPricingByBulkAll === 1 ) {

            // Loop and search by product id
            $.each( currentCart, function( i, v ) {
              // Search for colors with current type
              if ( parseInt( v.pID ) === ajaxProductID ) {
                // Add to current offset
                variantQtyOffset = variantQtyOffset + parseInt( v.qty );
              }
            });

          } else {

            // Loop and search by variant id
            $.each( currentCart, function( i, v ) {
              // Search for colors with current type
              if ( parseInt( v.vID ) === currentVariant.id ) {
                // Add to current offset
                variantQtyOffset = variantQtyOffset + parseInt( v.qty );
              }
            });

          }

          // If quantity is offset, add to user quantity controller
          if ( variantQtyOffset > 0 ) {
            // Combine into variantCurrQty
            variantCurrQty = ( variantQtyOffset + variantCurrQty );

            // Show messaging
            $( '#pricingAlert' ).addClass( 'show' );
          } else {
            // Hide messaging
            $( '#pricingAlert' ).removeClass( 'show' );
          }
        }

        // Check if variant is maxed out
        // Loop and search by variant id
        $.each( currentCart, function( i, v ) {
          // Search for colors with current type
          if ( parseInt( v.vID ) === currentVariant.id ) {
            if(v.qty >= variantMaxQty) variantMaxed = true;
          }
        });

        // Update pricing modifier
        if ( variantOptions.length ) {
          // Loop through each option and get value, then add
          $( variantOptions ).each( function( i, obj ) {
            // Get value
            let value = $( this ).find( 'option:selected' ).val().split( '|' );

            // Set value
            let fixedValue;

            // If no value found, set to zero
            if ( typeof value[1] !== 'undefined' && value[1] !== '' ) {
              fixedValue = value[1];
            } else {
              fixedValue = 0;
            }


            // Add to price modifier holder
            variantPriceModifier += parseFloat( fixedValue );
          });
        }

        // Set actual price
        if ( quantityPricingByBulk ) {
          // Use quantity pricing by bulk
          variantPriceCleaned = getPricingByBulk( variantCurrQty );
        } else {
          // Use default pricing
          variantPriceCleaned = variantPriceDefault;
        }

        // Use sliding rate pricing
        if ( variantSlidingRate.length > 0 ) {
          let slidingPrice = variantPriceCleaned;
          let slidingMax;

          // Loop through bulk pricing array and find
          // the correct price associated, based on qty
          $.each(currentVariant.variantEachScale, ( index, item ) => {
            // If the max isn't set, only go as far as the qty of the line item
            if( item.maxQuantity === '' ) {
              slidingMax = ( variantCurrQty + 1 );
            } else if ( item.maxQuantity > variantCurrQty ) {
              slidingMax = variantCurrQty;
            } else {
              slidingMax = item.maxQuantity;
            }

            // Result
            slidingPrice = setSlidingRate( slidingPrice, item.minQuantity, slidingMax, item.rate );
          });

          // Set minumum of $0.01 per unit
          if ( slidingPrice < 0.01 ) {
            slidingPrice = 0.01;
          }

          // Return result
          variantPriceCleaned = slidingPrice;
        }

        // Add on any modifiers to compiled pricing
        variantPriceCleaned = ( variantPriceCleaned + variantPriceModifier );

        // Handle undefined or NaN returns and show default
        if ( isNaN( variantPriceCleaned ) || ( variantPriceCleaned === '' ) || ( variantPriceCleaned === undefined ) ) {
          // Return default price
          variantPriceCleaned = variantPriceDefault;
        }

        // Clean and format price
        //let currencyOpts = { format: '%s%v', code: 'USD', symbol: '$' };
        //variantPriceCleaned = formatCurrency( variantPriceCleaned, currencyOpts );
        variantPriceCleaned = '$' + parseFloat( variantPriceCleaned ).toFixed( 2 );

        // Update price on UI
        // Only if variant is NOT maxed
        if(!variantMaxed) $( '#variantPrice' ).text( variantPriceCleaned );

        // Display proper engraving fields on products with engraving
        if ( variantEngraving.length ) {
          // Only move forward if wrapper of fields is found
          if ( $( '#engravingInputs' ).length ) {
            // Get value
            let engravingChoice = $( variantEngraving ).find( 'option:selected' ).val().split( '|' );

            // Display proper form
            if ( engravingChoice[0] === 'Varies' ) {
              // Display upload form
              $( '#engravingInputs' ).addClass( 'upload' );
            } else {
              // Display form from database
              $( '#engravingInputs' ).removeClass( 'upload' );
            }
          }
        }
      }

      function buildCustomOptions(setCustomOptions, setEngravingInputs, setEngravingFonts){
        let customOptionsFields = '';
        // Iterate through our options and build!
        $.each(setCustomOptions, function( i, v ) {
          // Next move onto field
          if ( isArray( v ) ) {
            // First print bold title
            customOptionsFields += '<span class="product__options__name">' + i + '</span>';
            
            // Open select menu
              if(v[0].isEngravingOption == 1 ){
                  customOptionsFields += '<select name="options[' + i + ']" data-engrave="yes" class="c-select__default product__options__select">';
              }else{
                  customOptionsFields += '<select name="options[' + i + ']" class="c-select__default product__options__select">';
              }
            

            // Loop array and build options for select menu
            $.each(v, function( index, optionData ) {
              // Option if no price
              if ( optionData.priceModifier > 0 ) {
                customOptionsFields += '<option value="' + optionData.value + '|' + optionData.priceModifier + '">' + optionData.value + ' -- +$' + optionData.priceModifier.toFixed(2) + '</option>';
              } else {
                customOptionsFields += '<option value="' + optionData.value + '|' + optionData.priceModifier + '">' + optionData.value + '</option>';
              }
            });

            // Close select menu
            customOptionsFields += '</select>';
            // If 'Engraved Text' is the option at hand
            if ( v[0].isEngravingOption == 1 ) {
              // If engraving fonts exist
              if ( setEngravingInputs ) {
                // Open wrapping HTML
                customOptionsFields += '<div id="engravingInputs" class="engraving__inputs"><div class="engraving__lines">';

                // Loop array and build inputs
                $.each(setEngravingInputs, function( i, z ) {
                  // First print bold title
                  customOptionsFields += '<span class="product__options__name">' + z.label + '</span>';

                  // Print out the input
                  customOptionsFields += '<input type="text" name="options[' + z.label + ']" class="c-input_text__default product__options__select js-engraving-input" value="" maxlength="' + z.characterLimit + '" />';
                });
                // Close wrapping HTML
                customOptionsFields += '</div><div class="engraving__upload"><span class="product__options__name">Engraving Upload</span><small>For varying engravings, please upload a spreadsheet. If you need a sample spreadsheet, <a href="/assets/downloads/NapTags-ExcelTemplate.xlsx" download="download" target="_blank">click here to download.</a></small><input type="file" name="customerUploads" class="c-input_text__default product__options__select" /></div></div>';
              }
              // console.log(ajaxProductCanHaveArtFilesAttached);
              
              if(ajaxProductCanHaveArtFilesAttached == 1){
                  customOptionsFields += '<div class="engraving__upload"><span class="product__options__name">Art Upload</span><label for="artUploads" class="c-input_submit__default -no-icon -no-margin js-file-upload-label">Upload a file</label><input style="display:none;" type="file" id="artUploads" name="artUploads" class="c-input_text__default product__options__select js-file-upload-input" /></div>';
                  
              }
              // If engraving fonts exist
              if ( setEngravingFonts ) {
                // First print bold title
                customOptionsFields += '<span class="product__options__name">Engraving Font</span>';

                // Open select menu
                customOptionsFields += '<select name="options[Engraving Font]" class="c-select__default product__options__select">';

                // Loop array and build options for select menu
                $.each(setEngravingFonts, function( i, z ) {
                  customOptionsFields += '<option value="' + z + '|0">' + z + '</option>';
                });

                // Close select menu
                customOptionsFields += '</select><br>';
                for (i = 0; i < fontImageUrls.length; i++) {
                    customOptionsFields +='<a href="'+fontImageUrls[i]+'" data-lc-options=\'{"width":600, "height":1000}\' id="fontExample">See Font Examples</a>';
                }
                
              }
            }
          } else {
            customOptionsFields += '<span class="product__options__name">' + v + '</span>';
            customOptionsFields += '<input type="text" name="options[' + v.replace('"','') + ']" class="c-input_text__default product__options__select" value="" />'
            if(ajaxProductCanHaveArtFilesAttached == 1) {
                customOptionsFields += '<div class="engraving__upload"><span class="product__options__name">Art Upload</span><label for="artUploads" class="c-input_submit__default -no-icon -no-margin js-file-upload-label">Upload a file</label><input style="display:none;" type="file" id="artUploads" name="artUploads" class="c-input_text__default product__options__select js-file-upload-input" /></div>';
            }
          }
        });
        return customOptionsFields;
      }

      // Build variant
      function buildVariant() {
        // Set values
        let setID = currentVariant.id;
        let setSKU = currentVariant.sku;
        let setTitle = currentVariant.title;
        let setColor = currentVariant.color.colorId;
        let setLength = parseFloat( currentVariant.length ).toString();
        let setWidth = parseFloat( currentVariant.width ).toString();
        let setHeight = parseFloat( currentVariant.height ).toString();
        let setMinQty = currentVariant.minQty || 1;
        let setMaxQty = parseInt(currentVariant.maxQty);
        let setCustomOptions = currentVariant.customOptions;
        let setCustomTextInputs = currentVariant.customTextOptions;
        let setEngravingInputs = currentVariant.engravingInputs;
        let setEngravingFonts = currentVariant.engravingFonts;
        let useQuantityPricingByBulk = currentVariant.useQuantityPricingByBulk;

        if(useQuantityPricingByBulk === 1) {
          let vbps = $( '.variantBulkPricing' );
          for (var i = 0; i < vbps.length; i++) {
            vbps[i].style.display = 'none';
          }
          let dbp = $( '#defaultBulkPricing' );
          if (dbp.length) {
            dbp[0].style.display = 'none';
          }
          let vbp = $( '#variantBulkPricing-' + setID );
          if (vbp.length) {
            vbp[0].style.display = 'block';
            quantityPricingByBulk = vbp.attr( 'data-bulk-pricing' );
          }
        }
        else {
          let vbps = $( '.variantBulkPricing' );
          for (var i = 0; i < vbps.length; i++) {
            vbps[i].style.display = 'none';
          }
          let dbp = $( '#defaultBulkPricing' );
          if (dbp.length) {
            dbp[0].style.display = 'block';
          }
          quantityPricingByBulk = $( el ).attr( 'data-bulk-pricing' );
        }

        currQty = currentVariant.minQty || 1;

        // Update SKU
        $( '#variantSKU' ).text( setSKU );

        // Update QTY min value
        if ( $( '#variantQtyController' ).length ) {
          // Set min attribute value
          $( '#variantQtyController' ).attr( 'min', setMinQty );
          $( '#variantQtyController' ).attr( 'value', setMinQty );
        }

        // Handle max QTY
        if($('#variantQtyController').val() > setMaxQty) $( '#variantQtyController' ).attr( 'value', setMaxQty );

        // Update type selector
        if ( $( '#variantSwitchType' ).length ) {
          // Loop type selector and select correct value
          $( '#variantSwitchType > option' ).each(function() {
            // Initially de-select
            $( this ).prop( 'selected', false );

            // If correct element, select
            if ( $(this).text().trim() === setTitle.trim() ) {
              $( this ).prop( 'selected', true );
            }
          });
        }

        // Update color selector
        if ( $( '#variantSwitchColor' ).length ) {
          // Re-work color options
          setColorOptions( setTitle );

          // Ensure current color is selected
          $( '#variantSwitchColor option[value="' + setColor + '"]' ).prop( 'selected', true );
        }

        // Update dimensions
        $( '#variantLength' ).text( setLength );
        $( '#variantWidth' ).text( setWidth );
        $( '#variantHeight' ).text( setHeight );

        // Update purchasableId in form
        $( '#variantOptionsID' ).attr( 'value', setID );

        // Update custom options if they exist
        if ( setCustomOptions || setCustomTextInputs ) {
          // We'll hold and append incoming
          // html to this variable
          let customOptionsFields = '';
          if (setCustomOptions !== null && setCustomOptions !== undefined) customOptionsFields += buildCustomOptions(setCustomOptions, setEngravingInputs, setEngravingFonts);

          // Apply new fields
          $( '#variantOptionsFormBuilder' ).html( customOptionsFields );
          attatchFileUploadEvents();
          listenForSide2EngravingSelectChanges();

          // Give newly added select menus a bit of functionality
          if ( $( 'select[name^="options"]' ).length ) {
            // On option change, update
            $( 'select[name^="options"]' ).change( function() {
              updateCurrentVariant();
            });
          }
        } else if (ajaxProductCanHaveArtFilesAttached == 1) {
            let customOptionsFields = '<div class="engraving__upload"><span class="product__options__name">Art Upload</span><label for="artUploads" class="c-input_submit__default -no-icon -no-margin js-file-upload-label">Upload a file</label><input style="display:none;" type="file" id="artUploads" name="artUploads" class="c-input_text__default product__options__select js-file-upload-input" /></div>';
            $( '#variantOptionsFormBuilder' ).html(customOptionsFields);
            attatchFileUploadEvents();
        } else {
          // Clear options
          $( '#variantOptionsFormBuilder' ).html( '' );
        }
        
        let fheight = ($(document).height()-30);
        $('#fontExample').lightcase({
            height: fheight,
            maxHeight: fheight,
            shrinkFactor: 1,
        });
        
        // Force update of variant in UI
        updateCurrentVariant();
        // Show new form
        $( '#variantOptionsFormWrapper' ).addClass( 'show' );
      }

        function listenForSide2EngravingSelectChanges() {
            let side2EngravingSelectElement = document.body.querySelector('select[name="options[Engrave 2nd Side]"]');
            let engravingInputs = document.body.querySelectorAll('.js-engraving-input');

            if (side2EngravingSelectElement && engravingInputs && engravingInputs.length) {
                function updateSide2EngravingInputs() {
                    const selectValue = side2EngravingSelectElement.value;
                    const isSide2EngravingEnabled = selectValue.toLowerCase().indexOf('yes') !== -1;
                    for (let i = 0; i < engravingInputs.length; i++) {
                        const engravingInput = engravingInputs[i];
                        const inputName = engravingInput.name;
                        const isSide2EngravingInput = inputName.toLowerCase().indexOf('side 2') !== -1;
                        if (isSide2EngravingInput) {
                            if (isSide2EngravingEnabled) {
                                engravingInput.style.pointerEvents = 'all';
                                engravingInput.style.opacity = '1';
                                engravingInput.previousSibling.style.opacity = '1';
                            } else {
                                engravingInput.style.pointerEvents = 'none';
                                engravingInput.style.opacity = '.5';
                                engravingInput.previousSibling.style.opacity = '.5';
                                engravingInput.value = '';
                            }
                        }
                    }
                }

                side2EngravingSelectElement.addEventListener('change', updateSide2EngravingInputs);
                updateSide2EngravingInputs();
            }
        }

        function attatchFileUploadEvents() {
            var fileUploadInputs = document.body.querySelectorAll('.js-file-upload-input');
            if (fileUploadInputs.length) {
                for (let i = 0; i < fileUploadInputs.length; i++) {
                    let currentInput = fileUploadInputs[i];
                    let currentLabel = currentInput.previousSibling;
                    if (currentLabel && currentLabel.tagName == 'LABEL') {
                        currentInput.addEventListener('change', function() {
                            if (currentInput.files.length) {
                                currentInput.classList.add('has-file');
                                currentLabel.innerHTML = currentInput.files[0].name;
                            } else {
                                currentInput.classList.remove('has-file');
                                currentLabel.innerHTML = 'Upload File';
                            }
                        });
                    }
                }
            } 
        }

      // Load and search variant data for match
      // return results
      function findVariant() {
        // Hide both variant and NA visuals
        $( '#variantOptionsLoader' ).removeClass( 'show' );
        $( '#variantOptionsNA' ).removeClass( 'show' );
        $( '#variantOptionsFormWrapper' ).removeClass( 'show' );

        // Re-work color options
        setColorOptions( typeSelector );

        // Check for variant data, load if it doesn't exist
        if ( variantData ) {
          // Data already exists, so let's go straight to searching
          // Let's establish some variables to hold during the process
          let variantMatched = false;
          // Combo variant switch or only by type?
          if ( typeSelector && colorSelector ) {
            // Loop json data and find a match
            $.each(variantData, function( i, v ) {
              // Search for matching title AND color id
              if ( v.title === typeSelector && parseInt( v.color.colorId ) === parseInt( colorSelector ) ) {
                // Set currentVariant to that of matched
                currentVariant = v;

                // Set variant as matched
                variantMatched = true;
              }
            });
          } else {
            // Loop json data and find a match
            $.each(variantData, function( i, v ) {
              // Search for matching title
              if ( v.title === typeSelector ) {
                // Set currentVariant to that of matched
                currentVariant = v;

                // Set variant as matched
                variantMatched = true;
              }
            });
          }
          // Handle a match or no match
          if ( variantMatched ) {
            // Match found, let's build the variant
            buildVariant();

            // Update URL in histoy if changed
            if ( currentVariant.id !== window.history.state.id && ( countTypes > 1 || countColors > 1 ) ) {
              // Push to history
              window.history.pushState( currentVariant, '', productURL + '/' + currentVariant.sku );

              // Update social sharing URLs
              $( '#shareFacebook' ).attr( 'href', 'https://facebook.com/sharer/sharer.php?u=' + window.location.href );
              $( '#shareTwitter' ).attr( 'href', 'https://twitter.com/home?status=' + window.location.href );
              $( '#sharePinterest' ).attr( 'href', 'https://pinterest.com/pin/create/button/?url=' + window.location.href );
              $( '#shareLinkedin' ).attr( 'href', 'https://linkedin.com/shareArticle?mini=true&url=' + window.location.href );
            }
          } else {
            // Change not available text based on case
            if ( $( '#variantSwitchColor' ).length ) {
              $( '#variantOptionsNA' ).text( 'Please try another color' );
            } else {
              $( '#variantOptionsNA' ).text( 'Not available' );
            }

            // Not found, show not available
            $( '#variantOptionsNA' ).addClass( 'show' );
          }
        } else {
          // Report back data has not loaded
          console.log( 'Data failed to load' );
        }
      }

      // Handle the history object to allow for seamlessly changing variants
      // along with URL's and no refreshing pages
      window.addEventListener( 'popstate', function(event) {
        // Set currentVariant to popstate data
        currentVariant = window.history.state;

        // Build variant
        buildVariant();

        // If menu is open, close
        if ( $( 'body' ).hasClass( 'has-menu-open' ) ) {
          $( 'body' ).removeClass( 'has-menu-open' );
        }

        // If cart is open, close
        if ( $( 'body' ).hasClass( 'has-cart-open' ) ) {
          $( 'body' ).removeClass( 'has-cart-open' );
        }

        // If request a quote is open, close
        if ( $( 'body' ).hasClass( 'has-request-quote' ) ) {
          $( 'body' ).removeClass( 'has-request-quote' );
        }

        // If custom order is open, close
        if ( $( 'body' ).hasClass( 'has-custom-order' ) ) {
          $( 'body' ).removeClass( 'has-custom-order' );
        }

        // If filters are open, close
        if ( $( 'body' ).hasClass( 'has-filters-open' ) ) {
          $( 'body' ).removeClass( 'has-filters-open' );
        }

        // Hide "NA" text
        $( '#variantOptionsNA' ).removeClass( 'show' );
      });

      //////
      //////
      ////// Inputs, selectors, and triggers
      //////
      //////

      // If type switch exists
      if ( $( '#variantSwitchType' ).length ) {
        // Set default
        typeSelector = $( '#variantSwitchType' ).find( 'option:selected' ).text().trim();

        // On type change, run check
        $( '#variantSwitchType' ).change( function() {
          // Get selected value
          typeSelector = $( this ).find( 'option:selected' ).text().trim();

          // Update variant
          findVariant();
        });
      }

      // If color switch exists
      if ( $( '#variantSwitchColor' ).length ) {
        // Set default
        colorSelector = $( '#variantSwitchColor' ).find( 'option:selected' ).val().trim();

        // On color change, run check
        $( '#variantSwitchColor' ).change( function() {
          // Get selected value
          colorSelector = $( this ).find( 'option:selected' ).val().trim();

          // Update variant
          findVariant();
        });
      }

      // If option switch exists
      if ( $( 'select[name^="options"]' ).length ) {
        // On option change, update
        $( 'select[name^="options"]' ).change( function() {
          updateCurrentVariant();
        });
      }

      // If quantity box exists
      if ( $( '#variantQtyController' ).length ) {
        // On quantity change, update
        $( '#variantQtyController' ).bind( 'keyup mouseup', function () {
          var max = parseInt($(this).attr('max'));
          if($(this).val() > max) $(this).val(max);
          
          // Grab qty from main controller
          currQty = $( this ).val();

          // Update all variant qty boxes to
          // match main qty controller
          $( '#variantOptionsQty' ).attr( 'value', currQty );

          // Force update of variant in UI
          updateCurrentVariant();
        });
      }

      // If add to cart engaged, run it's course
      $( '#variantOptionsForm' ).on('submit', function(e) {
        // Prevent cart from refreshing page, and use ajax
        e.preventDefault();
        // Add to cart
        addToCart( $(this) );
      });

      //////
      //////
      ////// Load data and initiate
      //////
      //////

      /**
       * Loop through the variant data objects looking for a SKU that matches our initial SKU.
       * If `isFirstCheck` is false try checking the variant data SKUs with a leading `0`.
       * @param {object} variantData Array of variant objects.
       * @param {boolean} isFirstCheck Is this our first run though the variant data?
       */
      function checkVariantData(variantData, isFirstCheck){
        $.each(variantData, function( i, v ) {
          var sku = v.sku.toString();
          if(!isFirstCheck) sku = '0'+v.sku.toString();
          // Search for matching title
          if ( sku === initialSKU) {
            // Set currentVariant to that of matched
            currentVariant = v;
            if(!isFirstCheck) currentVariant.sku = '0'+v.sku.toString();
          }
        });
      }

      function handleCurrentVariant(){
        // Spoof pop state
        window.history.replaceState( currentVariant, '', window.location );

        // Enable quantity
        $( '#variantQtyController' ).prop( 'disabled', false );

        // Enable type selector
        if ( $( '#variantSwitchType' ).length ) {
          $( '#variantSwitchType' ).prop( 'disabled', false );
        }

        // Enable color selector
        if ( $( '#variantSwitchColor' ).length ) {
          $( '#variantSwitchColor' ).prop( 'disabled', false );
        }

        // Build variant
        buildVariant();

        // Hide loader & product N.A.
        $( '#variantOptionsLoader' ).removeClass( 'show' );
        $( '#variantOptionsNA' ).removeClass( 'show' );

        // Show default form
        $( '#variantOptionsFormWrapper' ).addClass( 'show' );
      }

      // Load our variant data on page load
      
      $.ajax({
        data:{
          productId: ajaxProductID,
          CRAFT_CSRF_TOKEN: ajaxCSRFtoken,
        },
        method: 'POST',
        url: '/actions/pageworks/productsAndVariants/getProductVariantsJson'
      }).done(function( data ) {
        // Set variant data
        variantData = JSON.parse( data );
        // console.log(variantData);
        // Loop json data and find a match for initial
        
        for(var firstKey in variantData);
        ajaxProductCanHaveArtFilesAttached = variantData[firstKey].productCanHaveArtFilesAttached
        checkVariantData(variantData, true);

        // Check if we have a variant
        // If not try looking again with a leading `0` for the SKU
        if ( currentVariant ) handleCurrentVariant();
        else{
          checkVariantData(variantData, false);
          if(currentVariant) handleCurrentVariant();
        }
      }).fail(function( data ) {
        console.log( 'Fail: ' + data.responseText );
      });
    }

    destroy() {
        super.destroy();
    }
}
