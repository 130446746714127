
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */

export function buildQuery( params ) {
  return '?' + Object.entries( params ).map(( [key, value] ) => {
    return key + '=' + ( Array.isArray( value )? value.join( ',' ) : value );
  }).join( '&' );
}
