
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */

// Imports
import AbstractModule from './AbstractModule';
import { $window } from '../utils/environment';

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
      const input = document.body.querySelector(".js-art-file-input");
      const fileName = document.body.querySelector(".js-art-file-name");
      if (input){
          input.addEventListener('change', ()=>{
              if (input.files.length){
                  input.classList.add('has-file');
                  if (fileName){
                      fileName.innerHTML = input.files[0].name;
                  }
              }else{
                input.classList.remove('has-file');
              }
          });
      }
    }

    destroy() {
        super.destroy();
    }
}
