
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */

// Imports
import AbstractModule from './AbstractModule';
import { $window, requestAnimFrame } from '../utils/environment';

export default class extends AbstractModule {
    constructor(options) {
        super(options);
        this.loop       = null;
        this.transition = 1;
        this.waitTime   = 6;
        this.baseTime   = this.waitTime + this.transition;
        this.counter    = this.waitTime;
        this.slides     = this.$el.find('.js-slide');
        this.currSlide  = 1;
        this.time       = null;
        this.isDirty    = false;
        this.$cta       = this.$el.find('.js-cta');
        this.$headlines = this.$el.find('.js-copy');
        this.$timer     = this.$el.find('.js-timer');
    }

    init() {
        let cleanSlides = ()=>{
            this.$timer.removeClass('-dirty').addClass('-counting');
            this.slides.each(function(){
                if(!$(this).hasClass('-visible')) $(this).removeClass('-dirty');
            });

            this.$headlines.each(function(){
                if(!$(this).hasClass('-visible')) $(this).removeClass('-dirty');
            });

            this.isDirty = false;
        }
        
        let handleSlideChange = ()=>{
            this.$timer.removeClass('-counting').addClass('-dirty');
            
            let nextSlideID = (this.currSlide + 1 > this.slides.length) ? 1 : this.currSlide + 1;
            let $currentSlide   = this.$el.find('.js-slide[data-slide="'+this.currSlide+'"]');
            let $incomingSlide  = this.$el.find('.js-slide[data-slide="'+nextSlideID+'"]');

            let $currentCopy    = this.$el.find('.js-copy[data-slide="'+this.currSlide+'"]');
            let $incomingCopy   = this.$el.find('.js-copy[data-slide="'+nextSlideID+'"]');

            $currentSlide.removeClass('-visible').addClass('-dirty');
            $incomingSlide.addClass('-visible');

            $currentCopy.removeClass('-visible').addClass('-dirty');
            $incomingCopy.addClass('-visible');

            this.$cta.attr('href', $incomingSlide.data('link'));
         
            this.currSlide = nextSlideID;
            this.isDirty = true;
            this.counter = this.baseTime;
        }

        this.loop = ()=>{
            let timeNew   = Date.now();
            let deltaTime = (timeNew - this.time) / 1000;
            this.time     = timeNew;

            this.counter -= deltaTime;

            // The carousel is dirty and the transition has finished
            if(this.isDirty && this.counter <= (this.baseTime - this.transition)) cleanSlides();

            // Countdown is finished, if we're not dirty switch slides
            if(this.counter <= 0 && !this.isDirty) handleSlideChange();

            requestAnimFrame(this.loop);
        }

        (()=>{
            this.time = Date.now();
            this.$timer.addClass('-counting');
            this.loop();
        })();
    }

    destroy() {
        this.loop = ()=>{}
        super.destroy();
    }
}
