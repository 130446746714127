
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */
/*jshint latedef: nofunc */
/* global swal */

// Update cart data
export function updateCartData( data ) {
  // Hold data return here
  let myData;

  // Only run if we're returning items in cart
  if ( !$.isEmptyObject( data ) ) {
    // Let's throw our data into a JSON array
    myData = [];

    // Loop and build
    $.each( data, function( i, v ) {
      myData.push({
        pID: v.snapshot.productId,
        vID: v.purchasableId,
        qty: v.qty
      });
    });

    // JSON stringify
    myData = JSON.stringify( myData );
  } else {
    myData = 'null';
  }

  // Update data holder
  $( '#variantSwitch' ).attr( 'data-cart-items', myData );

  // Trigger change
  $( '#variantSwitchType' ).trigger( 'change' );
}

// Open cart
export function openCart() {
  // If menu is open, close
  if ( $( 'body' ).hasClass( 'has-menu-open' ) ) {
    $( 'body' ).removeClass( 'has-menu-open' );
  }

  // If filters are open, close
  if ( $( 'body' ).hasClass( 'has-filters-open' ) ) {
    $( 'body' ).removeClass( 'has-filters-open' );
  }

  // Reset scroll
  $( '.cart-drawer' ).animate({ scrollTop: 0 }, 600);

  // Open cart
  $('body').addClass( 'has-cart-open' );
}

// Update cart
export function refreshCart( openCart ) {
  // Whether we're opening upon refresh or not
  openCart = openCart || false;
  // Use ajax to get current cart and open if successful
  $.ajax({
    type: 'GET',
    url: '/ajax/cart-drawer',
    success: function(response) {
      // Update cart html
      $('#cart-contents').html(response);
      // Open cart?
      if ( openCart ) {
        // Open cart
        $('body').addClass( 'has-cart-open' );
      }
      // Tie in ajax removal to newly added items
      if ( $('#cart-contents').find('.remove-lineItem').length ) {
        // If remove from cart engaged, run it's course
        $('#cart-contents').find('.remove-lineItem').on('submit', function(e) {
          // Prevent cart from refreshing page, and use ajax
          e.preventDefault();
          // Remove from cart
          updateCart( $(this) );
        });
      }
      // Tie in ajax quantity adjuster to newly added items
      if ( $('#cart-contents').find('.update-lineItem').length ) {
        // If remove from cart engaged, run it's course
        $('#cart-contents').find('.update-lineItem').on('submit', function(e) {
          // Prevent cart from refreshing page, and use ajax
          e.preventDefault();
          // Update cart
          updateCart( $(this) );
        });
      }
    },
    error: function(response) {
      // Report back error
      console.log(response);
    }
  });
}

// Update cart count
export function updateCartCount(quantity) {
  var countWrapper = $('.quick-icons a.cart span').text(quantity);
}

// Serialize form data, even when it includes files
export function serializeFiles( form ) {
  var formData = new FormData(),
      formParams = form.serializeArray();

  $.each(form.find('input[type="file"]'), function(i, tag) {
    $.each($(tag)[0].files, function(i, file) {
      formData.append(tag.name, file);
    });
  });

  $.each(formParams, function(i, val) {
    formData.append(val.name, val.value);
  });

  // Return data to source of call
  return formData;
}

// Add to cart
export function addToCart(form) {
  // Get data from submission and serialize
  var data = serializeFiles(form),
      actionUrl = form.find('input[name=action]').val(),
      button = form.find('button.cart');

  // Disable button and show user an action is being taken
  button.prop( 'disabled', true );
  button.text( 'Adding' );

  // Send data onward
  var request = $.ajax({
    type: 'POST',
    url: actionUrl,
    data: data,
    processData: false,
    contentType: false
  });

  // Request successfully submitted
  request.done(function(msg) {
    if (msg.success) {
      // Update cart data with current cart
      if ( $( '#variantSwitch' ).length ) {
        updateCartData( msg.cart.lineItems );
      }
      // Update cart icon count
      updateCartCount( msg.cart.totalQty );
      // Open cart
      refreshCart( true );
      // Resets
      window.setTimeout(function () {
        // Reset button to original state
        button.prop( 'disabled', false );
        button.text( 'Add to Cart' );
        // Reset quantiy box
        $( '#variantQtyController, #variantOptionsQty' ).val( $( '#variantQtyController' ).attr( 'min' ) );
        // Trigger change
        $( '#variantSwitchType' ).trigger( 'change' );
      }, 600);
    } else {
      // Report back error
      swal('Error', msg.cart.errors.lineItems[0], 'error');
      // Resets
      window.setTimeout(function () {
        // Reset button to original state
        button.prop( 'disabled', false );
        button.text( 'Add to Cart' );
        // Reset quantiy box
        $( '#variantQtyController, #variantOptionsQty' ).val( $( '#variantQtyController' ).attr( 'min' ) );
      }, 600);
    }
  });

  // Error making request
  request.fail(function(jqXHR, textStatus) {
    swal('Error', textStatus.cart.errors.lineItems[0], 'error');
    // Resets
    window.setTimeout(function () {
      // Reset button to original state
      button.prop( 'disabled', false );
      button.text( 'Add to Cart' );
      // Reset quantiy box
      $( '#variantQtyController, #variantOptionsQty' ).val( $( '#variantQtyController' ).attr( 'min' ) );
    }, 600);
  });
}

// Update cart
export function updateCart(form) {
  // Get data from submission and serialize
  var data = serializeFiles(form),
      actionUrl = form.find('input[name=action]').val(),
      buttonUpdate = form.find('button.update-item'),
      buttonRemove = form.find('button.remove-item');

  // Disable button and show user an action is being taken
  buttonUpdate.prop( 'disabled', true );
  buttonUpdate.text( 'Updating' );
  buttonRemove.prop( 'disabled', true );
  buttonRemove.text( 'Removing' );

  // Send data onward
  var request = $.ajax({
    type: 'POST',
    url: actionUrl,
    data: data,
    processData: false,
    contentType: false
  });

  // Request successfully submitted
  request.done(function(msg) {
    if (msg.success) {
      // Update cart data with current cart
      if ( $( '#variantSwitch' ).length ) {
        updateCartData( msg.cart.lineItems );
      }
      // Update cart icon count
      updateCartCount(msg.cart.totalQty);
      // Refresh cart
      refreshCart( true );
      // Reset button to original state
      //buttonUpdate.prop( 'disabled', false );
      //buttonUpdate.text( 'Update' );
      //buttonRemove.prop( 'disabled', false );
      //buttonRemove.text( 'Remove' );
    } else {
      // Report back error
      swal('Error', msg.cart.errors.lineItems[0], 'error');
      // Reset button to original state
      buttonUpdate.prop( 'disabled', false );
      buttonUpdate.text( 'Update' );
      buttonRemove.prop( 'disabled', false );
      buttonRemove.text( 'Remove' );
    }
  });

  // Error making request
  request.fail(function(jqXHR, textStatus) {
    swal('Error', textStatus.cart.errors.lineItems[0], 'error');
    // Reset button to original state
    buttonUpdate.prop( 'disabled', false );
    buttonUpdate.text( 'Update' );
    buttonRemove.prop( 'disabled', false );
    buttonRemove.text( 'Remove' );
  });
}


// Load initital cart
export function loadInitialCart() {
  // Use ajax to get current cart and open if successful
  $.ajax({
    type: 'GET',
    url: '/ajax/cart-drawer',
    success: function(response) {
      // Update cart html
      $('#cart-contents').html(response);
      // Tie in ajax removal to newly added items
      if ( $('#cart-contents').find('.remove-lineItem').length ) {
        // If remove from cart engaged, run it's course
        $('#cart-contents').find('.remove-lineItem').on('submit', function(e) {
          // Prevent cart from refreshing page, and use ajax
          e.preventDefault();
          // Remove from cart
          updateCart( $(this) );
        });
      }
      // Tie in ajax quantity adjuster to newly added items
      if ( $('#cart-contents').find('.update-lineItem').length ) {
        // If remove from cart engaged, run it's course
        $('#cart-contents').find('.update-lineItem').on('submit', function(e) {
          // Prevent cart from refreshing page, and use ajax
          e.preventDefault();
          // Update cart
          updateCart( $(this) );
        });
      }
    },
    error: function(response) {
      // Report back error
      console.log(response);
    }
  });
}
