
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */

// Imports
import AbstractModule from './AbstractModule';
import { $window } from '../utils/environment';

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
      // On change, go to URL
      $( this.el ).change( function() {
        window.location = $(this).val();
      });
    }

    destroy() {
        super.destroy();
    }
}
