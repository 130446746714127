
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */
/* globals currURI */

// Imports
import AbstractModule from './AbstractModule';
import { $window } from '../utils/environment';
import { addToCart } from '../utils/cart';
import { isArray } from '../utils/is';
import { addToArray, arrayContains } from '../utils/array';

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
        //We are doing this to mirror the rest of the sites JS
        const selectElement = document.querySelectorAll('.product-hierarchy');
        for (var i = 0; i < selectElement.length; i++) {
            selectElement[i].addEventListener('change', function (element) {
                var dropDownType = '';
                switch(element.srcElement.getAttribute('data-valueType')) {
                    case 'tagStyle':
                        document.querySelector('[data-valueType="tagMaterial"]').options.length = 0;
                        document.querySelector('[data-valueType="tagMaterial"]').classList.add('loading');
                        document.querySelector('[data-valueType="tagThickness"]').options.length = 0;
                        document.querySelector('[data-valueType="tagThickness"]').classList.add('loading');
                        document.querySelector('[data-valueType="tagColor"]').options.length = 0;
                        document.querySelector('[data-valueType="tagColor"]').classList.add('loading');
                        dropDownType = 'tagStyle';
                        
                        var selectedOption = element.srcElement.options[element.srcElement.selectedIndex].text;
                        if(selectedOption=='Engraved/Marked'){
                            document.getElementById('engravings').style.display = "block";
                        }else{
                            document.getElementById('engravings').style.display = "none";
                        }
                        var finalPrice = 0.00; 
                        var finalePriceDisplay = 0.00;
                        document.getElementById('perUnit').innerHTML = '0.00';
                        document.getElementById('totalPrice').innerHTML = '0.00';
                        document.getElementById('submit').disabled = true;
                        break;
                    case 'tagMaterial':
                        document.querySelector('[data-valueType="tagThickness"]').options.length = 0;
                        document.querySelector('[data-valueType="tagThickness"]').classList.add('loading');
                        document.querySelector('[data-valueType="tagColor"]').options.length = 0;
                        document.querySelector('[data-valueType="tagColor"]').classList.add('loading');
                        dropDownType = 'tagMaterial';
                        var finalPrice = 0.00; 
                        var finalePriceDisplay = 0.00;
                        document.getElementById('perUnit').innerHTML = '0.00';
                        document.getElementById('totalPrice').innerHTML = '0.00';
                        document.getElementById('submit').disabled = true;
                        break;
                    case 'tagThickness':
                        document.querySelector('[data-valueType="tagColor"]').options.length = 0;
                        document.querySelector('[data-valueType="tagColor"]').classList.add('loading');
                        dropDownType = 'tagThickness';
                        if(pricingScales != null){
                            pricingScale = pricingScales[element.srcElement.value];
                        }
                        break;
                
                }
                var selectedValues = {selectedValueId:element.srcElement.value, valueType: dropDownType};
                getDataForDropdowns(selectedValues);
                
            });
        }
        function getDataForDropdowns(selectedValues){
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                    var data = JSON.parse(xhttp.responseText).data;
                    var keys = Object.keys(data);
                    var entries = Object.entries(data);
                    var select;
                    var dropDownData;
                    var selected = false;
                    for (var i = 0; i < entries.length; i++) {
                        var key = keys[i];
                        var value = entries[i];
                        var str = '[data-valueType="'+key+'"]'; 
                        select = document.querySelector(str);
                        dropDownData = Object.entries(value[1]);
                        select.classList.remove('loading');
                        if(dropDownData.length > 1){
                            select.disabled = false;
                        }
                        for (var k = 0; k < dropDownData.length; k++) {
                            var dataPoint = dropDownData[k];
                            if(dataPoint[0] == 'prices'){
                                pricingScales = dataPoint[1];
                                continue;
                            }
                            switch (dataPoint[0]){
                                case 0:
                                    selected = true;
                                    break;
                                case 'none':
                                    selected = true;
                                    select.disabled = true;
                                    break;
                                    
                                default:
                                    selected = false;
                            
                            }
                            select.options[select.options.length] = new Option(dataPoint[1], dataPoint[0], selected, selected);
                        }
                    }
                }
            };
            xhttp.open("post", "custom-tag-configurator/getNextLevel", true);
            xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhttp.send('data='+JSON.stringify(selectedValues)+'&'+window.csrfTokenName+'='+window.csrfTokenValue);
        }
        var currentTotalPrice = 0;
        var priceAffectingElements = document.querySelectorAll('[data-PriceAffecting="true"]');
        for (var i = 0; i < priceAffectingElements.length; i++) {
            priceAffectingElements[i].addEventListener('change', function (element) {
                
                
                
                var purchasedQty;
                var qty = parseFloat(document.getElementById('qty').value);
                purchasedQty = qty;
                if(qty >= 100){
                    qty = 100;
                }
                var sqInches = checkSqInches();
                if(sqInches == false){
                    errorOutForm('Invalid Square Inches Value');
                    return sqInches;
                }else{
                    document.getElementById('current_sq_in').classList.remove("error");
                }
                if(pricingScale == null){
                    var finalPrice = 0.00; 
                    var finalePriceDisplay = 0.00;
                    document.getElementById('perUnit').innerHTML = '0.00';
                    document.getElementById('totalPrice').innerHTML = '0.00';
                    document.getElementById('submit').disabled = true;
                    return;
                }
                var arrPriceLevel;
                var pricingLevelName; 
                var pricingLevelValue;
                var basePrice = '';
                for (var i = 0; i < pricingScale.length; i++) {
                    var arrPriceLevel = Object.entries(pricingScale[i]);
                    if(pricingScale[i].sqInches == Math.floor(sqInches)){
                        var scaleSqIn = pricingScale[i].sqInches; 
                        for (var j = 0; j < arrPriceLevel.length; j++) {
                            pricingLevelName = arrPriceLevel[j][0];
                            pricingLevelValue = arrPriceLevel[j][1]
                            if(pricingLevelName.substring(0,3) == "col"){
                                continue;
                            }
                            if(pricingLevelName == 'qty'+qty){
                                basePrice = parseFloat(pricingLevelValue);
                            }
                        }
                    }

                    
                }
                if(basePrice == ''){
                    //some sort of error, base price was not found.
                    errorOutForm('Unknown Error');
                    return false;
                }
                var currentBasePrice = basePrice;
                var priceAffectingDropDowns = document.querySelectorAll('select[data-PriceAffecting="true"]');
                for (var d = 0; d < priceAffectingDropDowns.length; d++) {
                    var dd = priceAffectingDropDowns[d];
                    if(dd.name != 'options[tagThickness]'){
                        for (var l = 0; l < dd.options.length; l++) {
                            if(dd.options[l].selected){
                                currentBasePrice += parseFloat(dd.options[l].dataset.priceadjust);
                            }
                        
                        }
                    }
                    
                }
                var finalPrice = parseFloat(currentBasePrice)*parseFloat(purchasedQty); 
                var finalePriceDisplay = (Math.round(finalPrice*100)/100).toFixed(2);
                document.getElementById('perUnit').innerHTML = (Math.round((finalePriceDisplay/purchasedQty)*100)/100).toFixed(2);
                document.getElementById('totalPrice').innerHTML = finalePriceDisplay;
                document.getElementById('submit').disabled = false;
            });
        }
        
        function checkSqInches(){
            var width = parseFloat(document.getElementById('width').value);
            var height = parseFloat(document.getElementById('height').value);
            var currentSqInches = width * height;
            document.getElementById('current_sq_in').innerHTML = 'Current Sq Inches: '+currentSqInches;
            if(currentSqInches >= 25){
                //document.getElementById('current_sq_in').className += ' error';
                document.getElementById('current_sq_in').classList.add('error');
                return false;
            }else{
                return currentSqInches;
            }
            
        }
        var form = document.getElementById('customTagForm');
        
        form.addEventListener('submit', function(e){
            
            var validate = validateForm();
            if(validate == false){
                form.addEventListener('change', function(e){
                    validateForm();
                });
                e.preventDefault();
                e.stopPropagation();
                return false;
            }else{
                return true;
            }
            
        });
        
        
        function validateForm(){ 
           var valid= true;
           var qty = document.getElementById('qty');
           var width = document.getElementById('width');
           var height = document.getElementById('height');
           var style = document.getElementById('tagStyle');
           var material = document.getElementById('tagMaterial');
           var thickness = document.getElementById('tagThickness');
           var color = document.getElementById('tagColor');
           
           if(isNaN(qty.value) == true || qty <= 0 || qty < 99){
               valid = false;
               qty.classList.add('error');
           }else{
               qty.classList.remove('error');
           }
           
           if(isNaN(width.value) == true){
               valid = false;
               width.classList.add('error');
           }else{
               width.classList.remove('error');
           }
           
           if(isNaN(height.value) == true){
               valid = false;
               height.classList.add('error');
           }else{
               height.classList.remove('error');
           }
           
           var sqInches = width.value * height.value; 
           if(sqInches >= 25){
               valid = false
               width.classList.add('error');
               height.classList.add('error');
               document.getElementById('current_sq_in').classList.add('error');
           }else{
               width.classList.remove('error');
               height.classList.remove('error');
               document.getElementById('current_sq_in').classList.remove('error');
           }
           
           if(style.value == 0){
               valid = false;
               style.classList.add('error');
           }else{
               style.classList.remove('error');
           }
           
           if(material.value == 0){
               valid = false;
               material.classList.add('error');
           }else{
               material.classList.remove('error');
           }
           
           if(thickness.value == 0){
               valid = false;
               thickness.classList.add('error');
           }else{
               thickness.classList.remove('error');
           }
           
           if(color.value == 0 && color.options.length > 1){
               valid = false;
               color.classList.add('error');
           }else{
               color.classList.remove('error');
           }
           
           if(valid == true){
               document.getElementById('submit').disabled = false;
           }else{
               document.getElementById('submit').disabled = true;
           }
           
           return valid;
           
        }
        
        function errorOutForm(status){
            document.getElementById('totalPrice').innerHTML = "0.00";
            document.getElementById('submit').disabled = true;
        }
        
        
        if (!Object.entries) {
            Object.entries = function( obj ){
                var ownProps = Object.keys( obj ),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];
                return resArray;
            };
        }
        
        if (!Object.keys) {
          Object.keys = (function() {
            'use strict';
            var hasOwnProperty = Object.prototype.hasOwnProperty,
                hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
                dontEnums = [
                  'toString',
                  'toLocaleString',
                  'valueOf',
                  'hasOwnProperty',
                  'isPrototypeOf',
                  'propertyIsEnumerable',
                  'constructor'
                ],
                dontEnumsLength = dontEnums.length;
        
            return function(obj) {
              if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
                throw new TypeError('Object.keys called on non-object');
              }
        
              var result = [], prop, i;
        
              for (prop in obj) {
                if (hasOwnProperty.call(obj, prop)) {
                  result.push(prop);
                }
              }
        
              if (hasDontEnumBug) {
                for (i = 0; i < dontEnumsLength; i++) {
                  if (hasOwnProperty.call(obj, dontEnums[i])) {
                    result.push(dontEnums[i]);
                  }
                }
              }
              return result;
            };
          }());
        }
       var file = document.getElementById('customerUploads');
       file.addEventListener('change', function (element) {
          var fileNameElement = document.getElementById('fileName');
          if(element.target.files[0].name != 0){
              fileNameElement.innerHTML = 'File Name: '+element.target.files[0].name;
          }
          
       });
    }
    
    destroy() {
        super.destroy();
    }
    
}