
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */

// Imports
import { $window } from './environment';
import ScrollMagic from 'scrollmagic';
import { TimelineMax, TweenMax, Power1 } from 'gsap';
require('scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap');

export function ParallaxScroll( target ) {
  // Setup ScrollMagic smController and smScene
  let smController = new ScrollMagic.Controller();
  let smScene;
  let smTween;

  // Execute on elements
  $( target ).each(function( i, el ) {
    // Create variables for holding purposes
    let tmProps = {};
    let smProps = {};
    let smAttribute;
    let smAttributeValue;
    let smTriggerHook;
    let smDuration;
    let smDelay;

    // Get desired effect via data attributes
    smAttribute = $( el ).attr( 'data-attribute' ) ? $( el ).attr( 'data-attribute' ) : 'opacity';
    smAttributeValue = $( el ).attr( 'data-attribute-value' ) ? $( el ).attr( 'data-attribute-value' ) : '0';
    smTriggerHook = $( el ).attr( 'data-trigger-hook' ) ? $( el ).attr( 'data-trigger-hook' ) : '1';
    smDuration = $( el ).attr( 'data-duration' ) ? $( el ).attr( 'data-duration' ) : '40%';
    smDelay = $( el ).attr( 'data-delay' ) ? $( el ).attr( 'data-delay' ) : '0';

    // Fill our dynamic props for TweenMax
    tmProps[smAttribute] = smAttributeValue;
    tmProps.delay = smDelay;
    tmProps.ease = 'Power1.easeInOut';

    //console.log(tmProps);

    // Create tween
    smTween = new TimelineMax ()
      .add([
        TweenMax.from( el, 1, tmProps )
      ]);

    // Fill our dynamic props for ScrollMagic
    smProps.triggerElement = el;
    smProps.triggerHook = smTriggerHook;
    smProps.duration = smDuration;

    //console.log(smProps);

    // Create scene
    smScene = new ScrollMagic.Scene( smProps )
      .setTween( smTween )
      .addTo( smController );
  });

  // Update scene onresize
  if ( $( target ).length >= 1 ) {
    $window.resize( function() {
      smController.updateScene( smScene, true );
    });
  }
}
