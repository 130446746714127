/* jshint esnext: true */
let uid = 0;
import { $document, $window, $html, $body } from '../utils/environment';

/**
 * Abstract Module
 */
export default class
{
    constructor(options)
    {
        this.$document = $document;
        this.$window = $window;
        this.$html = $html;
        this.$body = $body;
        this.$el = options.$el || null;
        this.el  = options.el  || null;

        // Generate a unique module identifier
        this.uid = 'm-' + uid++;
        // Use jQuery's data API to "store it in the DOM"
        this.$el.data('uid', this.uid);
    }

    init() {}

    destroy()
    {
        if (this.$el) {
            this.$el.removeData('uid');
        }
    }
}
