
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */

// Imports
import AbstractModule from './AbstractModule';
import { $window } from '../utils/environment';
import { addToArray } from '../utils/array';
import { buildQuery } from '../utils/build';

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
      function submitForm(){
        // Set input targets
        let form = $( '#productFilters' );
        let action = form.attr( 'action' );
        let shapes = form.find( 'input[name="shape[]"]:checked' );
        let materials = form.find( 'input[name="material[]"]:checked' );
        let colors = form.find( 'input[name="color[]"]:checked' );
        let productUse = form.find( 'input[name="productUse[]"]:checked' );
        
        // Query params holder
        let params = [];

        // Look for shapes
        if ( shapes.length ) {
          params.shape = shapes.map(function() {
            return $( this ).val();
          }).get();
        }

        // Look for materials
        if ( materials.length ) {
          params.material = materials.map(function() {
            return $( this ).val();
          }).get();
        }

        // Look for colors
        if ( colors.length ) {
          params.color = colors.map(function() {
            return $( this ).val();
          }).get();
        }
        
        if ( productUse.length ) {
            params.productUse = productUse.map(function() {
              return $( this ).val();
            }).get();
          }


        // Build query
        let queryString = buildQuery( params );

        // Query exists, submit form
        if ( queryString == '?' ) {
            queryString = "";
          // Submit form
        }
          window.location.href = ( action + queryString );
      }

      // Filters submitting
      $( '.js-apply-fitlers' ).click(function( e ) {
        // Prevent submission
        e.preventDefault();
        submitForm();        
      });

      $('#productFilters').on('click change', 'input', function(){
        submitForm();
      });

      // Filters clearing
      $( '.js-clear-filters' ).click(function( e ) {
        // Prevent default
        e.preventDefault();

        // Set input targets
        let form = $( '#productFilters' );
        let action = form.attr( 'action' );

        // Clear checkboxes
        form.find( 'input:checkbox' ).prop( 'checked', false );

        // Submit form
        window.location.href = action;
      });

    	// Filters toggler
    	$( '.js-filters-toggle' ).click(function() {
    		// If already open, close, etc.
    		if ( $( 'body' ).hasClass( 'has-filters-open' ) ) {
    			$( 'body' ).removeClass( 'has-filters-open' );
    		} else {
    			$( 'body' ).addClass( 'has-filters-open' );
    		}
    		// Scroll to top
    		$( '.filters-wrapper' ).animate( { scrollTop: 0 }, 600 );
    	});

      // Toggle filter types
      $( '.js-filter-type-toggle' ).click(function() {
        if ( $( this ).hasClass( 'filter-closed' ) ) {
          $( this ).removeClass( 'filter-closed' );
          localStorage.removeItem($( this ).text());
        } else {
          $( this ).addClass('filter-closed' );
          localStorage.setItem($( this ).text(), 'filter-closed');
         
        }
      });
      
      $('.js-filter-type-toggle').each(function(){
          if(localStorage.getItem($( this ).text()) != null){
              $( this ).addClass('filter-closed' );
          }
      });
      
      // Resize nav
      $window.resize(function() {
        $( 'body' ).removeClass( 'has-filters-open' );
      	$( '.filters-wrapper' ).animate( { scrollTop: 0 }, 600 );
      });
    }

    destroy() {
        super.destroy();
    }
}
