
// Directives
'use strict';
/* jshint unused: false */
/* jshint esnext: true */

// Imports
import AbstractModule from './AbstractModule';
import { $window } from '../utils/environment';
import { openCart } from '../utils/cart';

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
    	// Navigation toggler
    	$( '.js-nav-toggle' ).click(function() {
    		// If already open, close, etc.
    		if ( $( 'body' ).hasClass( 'has-menu-open' ) ) {
    			$( 'body' ).removeClass( 'has-menu-open' );
    		} else {
    			$( 'body' ).addClass( 'has-menu-open' );
    		}
    		// Scroll to top
    		$( 'nav' ).animate({ scrollTop: 0 }, 600);
    	});
      // Resize nav
      $window.resize(function() {
      	$( 'nav' ).animate({ scrollTop: 0 }, 600);
      });
      // Cart toggler
      $( '.js-cart' ).click( function( e ) {
        // Prevent loading of actual linkage
        e.preventDefault();

        // Ensure other overlays are closed
        $( 'body' ).removeClass( 'has-request-quote has-custom-order' );

        // Toggle opening based on current state
        if ( $( 'body' ).hasClass( 'has-cart-open' ) ) {
          $( 'body' ).removeClass( 'has-cart-open' );
        } else {
          // Open drawer
          openCart();
        }
      });
    }

    destroy() {
        super.destroy();
    }
}
